/** @format */

//flip screen d1-h1
.page {
  transition: transform 0.5s ease;
}

.hourly {
  transform: perspective(3000px) rotateY(360deg);
}

.dark-mode {
  background-color: #1e1e1e;
  color: white; /* Colore del testo */
}
.ant-table {
  background-color: #0c115728 !important;
}

.ant-table-tbody {
  background-color: #0c115728 !important;
}
.ant-table-footer {
  background-color: white; /* Sostituisci con il colore di sfondo delle celle desiderato */
  color: #40c226;
}
.ant-table-header {
  background-color: #0c115763 !important; /* Sostituisci con il colore di sfondo dell'intestazione desiderato */
}
.ant-table-thead > tr > th {
  background-color: #0c115763 !important;
  color: white !important;
}
.ant-table-tbody > tr > *:hover {
  background-color: #866a0b !important;
}
.ant-table-tbody > tr > td {
  background-color: #52ace427 !important;
  color: white !important;
}
.custom-row > tr {
  background-color: #a00a0a !important;
  color: white !important;
}

.ant-pagination-item > a {
  background-color: #1e1e1e;
  color: white !important;
}

// .ant-table: La classe principale per la tabella.
// .ant-table-thead: La classe per l'intestazione della tabella.
// .ant-table-tbody: La classe per il corpo della tabella.
// .ant-table-row: La classe per le righe della tabella.
// .ant-table-cell: La classe per le celle della tabella.
// .ant-table-header: La classe per l'intestazione delle colonne della tabella.
// .ant-table-footer: La classe per il piè di pagina della tabella.
// .ant-table-pagination: La classe per la paginazione della tabella.

//ticker
.custom-ticker-container > * {
  height: 80px;
  padding: 10px;
}
.custom-ticker > * {
  padding: 10px;
}

.ticker-item > * {
  height: 80px;
  padding: 10px;

  font-size: 10px;
  color: #333;
  margin-right: 10px;
  z-index: 10;
}

//select
.basic-multi-select {
  z-index: 300;
}

//chatgpt
.cs-message-input,
.cs-message-input__tools,
.cs-message-input__content-editor-wrapper {
  padding: 10 !important;
}
.cs-button--attachment {
  display: none !important;
}

//colorssssss
.gradient-gold {
  background-image: radial-gradient(
    circle at 50% -20.71%,
    #ffffd4 0,
    #fffec5 25%,
    #f2deb5 50%,
    #d1c0a6 75%,
    #b1a698 100%
  );
}

.items-center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.items-center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.btn-simple {
  color: white !important;
  border-width: 0 !important;
}
